import React from 'react';
import { IButtonACF } from 'src/types/IButtonACF';
import Button from '@components/views/Button';

export interface HeadingCtaProps {
  title: string;
  button: IButtonACF;
  level?: keyof JSX.IntrinsicElements;
}
const HeadingCta: React.FunctionComponent<HeadingCtaProps> = ({
  title,
  button,
  level: Tag = 'h2',
}) => {
  const { variant = 'GHOST', url, title: buttonTitle } = button;
  const hasButton = url && buttonTitle;
  const hasElements = title || hasButton;

  if (!hasElements) return null;

  return (
    <div className="flex justify-between items-center mb-64 flex-wrap gap-24">
      <Tag
        className="text-neutral-9 text-4xl font-extrabold"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {hasButton && (
        <Button href={url} variant={variant}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};
export default HeadingCta;
