import EventTileSimple from '@components/views/Events/EventTileSimple';
import TilesList from '@components/views/TilesList';
import omit from 'lodash/omit';
import { IButtonACF } from 'src/types/IButtonACF';
import { convertEventDataToTileProps } from '../utils/convertEventDataToTileProps';
import { useState } from 'react';

type HighlightedEventsProps = {
  button: IButtonACF;
  title?: string;
  items: any[];
};

const HighlightedEvents = (props: HighlightedEventsProps) => {
  const [activeElement, setActiveElement] = useState<HTMLElement | null>();
  const { items } = props;

  const eventData =
    items && items.length > 0
      ? items.map((event) => convertEventDataToTileProps(event))
      : [];

  return (
    <div onMouseLeave={() => setActiveElement(null)}>
      <TilesList
        {...omit(props, 'items')}
        items={eventData}
        ItemComponent={(arg) => (
          <EventTileSimple
            onMouseEnter={() => setActiveElement(arg.ID)}
            variant={
              activeElement
                ? activeElement !== arg.ID
                  ? 'GRAYSCALE'
                  : 'HOVER'
                : undefined
            }
            theme="GRID"
            {...arg}
          />
        )}
      />
    </div>
  );
};

export default HighlightedEvents;
