import Container from '@components/containers/Container';
import { ComponentProps } from 'react';
import { IButtonACF } from 'src/types/IButtonACF';
import HeadingCta from '../HeadingCta';

interface TilesListProps<T> {
  title?: string;
  button: IButtonACF;
  items: T[];
  ItemComponent: (args: ComponentProps<any>) => JSX.Element;
}

const TilesList = <T,>(props: TilesListProps<T>) => {
  const { title, button, items, ItemComponent } = props;
  const hasHeading = title || button?.url;
  const hasItems = items && items.length > 0;

  return (
    <>
      {items && !!items.length && (
        <div className="bg-neutral-1 py-48 lg:py-64 xl:pt-88 xl:pb-80">
          <Container>
            {hasHeading && <HeadingCta title={title || ''} button={button} />}
            {hasItems && (
              <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-32">
                {items.map((item, key) => (
                  <div
                    key={`item-${key}`}
                    className="min-h-[28rem] md:min-h-[34.4rem]"
                  >
                    <ItemComponent {...item} />
                  </div>
                ))}
              </div>
            )}
          </Container>
        </div>
      )}
    </>
  );
};

export default TilesList;
